:root {
  --white: #ffffff;
  --light: #eeeeee;
  --dark: #14161e;
  --primary: #0076ba;
  --secondary:  #1d96d1;
  --info: #53bfed;
  --accent1: #1db100;
  --accent2: #00cc39;
  --accent3: #7de47d;
  --success: #00c56d;
  --warning: #ffc107;
  --danger: #dc3545;
}
body {
  background: linear-gradient(180deg, var(--primary) 0%, var(--accent1) 100%);
  background-size: 100vw 100vh;
  background-attachment: fixed;
}