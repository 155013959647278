
$font-family-sans-serif: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-base:       $font-family-sans-serif !default;

// Check https://huemint.com/bootstrap-plus/ to create a color theme.
$white: #ffffff;

$theme-colors: (
    "light":      #eeeeee,
    "dark":       #14161e,
    "primary":    #0076ba,
    "secondary":  #1d96d1,
    "info":       #53bfed,
    "accent1":    #1db100,
    "accent2":    #00cc39,
    "accent3":    #7de47d,
    "success":    #00c56d,
    "warning":    #ffc107,
    "danger":     #dc3545,
);
$link-color: #0076ba;
$link-hover-color: #14161e;
$table-th-font-weight: 600;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';